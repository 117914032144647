.loading {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

/* You can add global styles to this file, and also import other style files */

h1 {
  font-family: Merriweather, serif;
  font-size: 1.4rem;
  font-weight: bold;
}
h2 {
  font-size: 1.2rem;
  font-weight: bold;
}
h2, h3 {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: lighter;
}
body {
  font-family: Lato, Arial, Helvetica, sans-serif;
  background-color: #f5f5f5;
  color: #444;
}

input, button {
  font-family: Lato, Arial, Helvetica, sans-serif;
}

input[type=button], input[type=submit], button {
  border: 0;
}

input[type=button]:disabled, input[type=submit]:disabled, button:disabled {
  opacity: 0.5;
  cursor: default;
}

.link {
  cursor: pointer;
}

.flash-success {
  background-color: #65ffbf;
  text-align: center;
  padding: 6px;
  margin-bottom: 20px;
}

.error {
  margin-top: 10px;
  font-size: 80%;
  color: red;
}

#sorting {

}

#auto-update-container {
   float: right;
   font-size: 0.8rem;
   margin-right: 20px;
   margin-bottom: 20px;
}

#auto-update-container span {
  line-height: 33px;
  vertical-align: middle;
  margin-right: 6px;
}

.auto-update-toggle {
  cursor: pointer;
  vertical-align: middle;
  height: 18px;
}

#top-container {
  margin: 0 auto;
  max-width: 1594px;
  -webkit-backface-visibility: hidden;
}

#top-container a {
  font-weight: bold;
  margin-right: 15px;
}

#top-links img {
  height: 20px;
}

#top-links .overwatch-icon, #contact img {
  height: 16px;
}

#top-links .smash-icon {
  height: 16px;
}

#top-container .link {
  margin-left: 6px;
  vertical-align: middle;
}

#contact a {
  margin-right: 0;
}

#home-container {
  margin: 0 auto;
  max-width: 1594px;
  -webkit-backface-visibility: hidden;
}

#home-container-content {
  margin-right: 40px;
  margin-left: 40px;
  padding-bottom: 20px;
}

#home-container-text {
  padding-top: 60px;
  padding-bottom: 60px;
  display: table;
}

#home-container-text h1 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.25rem;
  width: 100%;
}

#home-container-text span {
  line-height: 1.7rem;
}

#home-container-centered {
  display: table-cell;
  vertical-align:middle;
  text-align: center;
}

#home-container-image {
  float: right;
}

#home-container-image img {
  height: 340px;
}

#container {
    margin: 0 auto;
    max-width: 1594px;
    -webkit-backface-visibility: hidden;
}

#content {
    /*padding-top: 10px;*/
}

.flash.success {
  background-color: #abd473;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 2px;
  /*display: inline-block;*/
}


.__react_component_tooltip {
  opacity: 0.9;
  padding: 4px 8px;
  background-color: #000 !important;
  border-radius: .25rem;
}

.__react_component_tooltip.place-top {
  margin-top: -7px;
}

.player {
   background-color: #1A1A1A;
   margin: 0 11px 10px 11px;
   /* height: 284px; */
   display: none;
}

.player .displayName {
  color: #fff;
  margin-left: 4px;
}

.player .twitchLogo {
  width: 26px;
  height: 26px;
}

.player .player-info-title {
  color: #fff;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.player-info-title-link, .player-info-title-link:hover {
  color: #fff;
}

.player .player-info-title .player-info-title-left {
  display: table-cell;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
}

.player .player-back {
  padding: 5px;
  cursor: pointer;
  float: left;
}

.player .player-index {
  font-size: 0.8rem;
  color: #999;
  vertical-align: bottom;
}

.player .player-next {
  padding: 5px;
  cursor: pointer;
  float: right;
}

.player .player-next:hover, .player .player-back:hover {
  color: #FDF178;
}

.player .disabled, .player .disabled:hover {
  color: #333;
  cursor: auto;
}

.player .player-info-details {
  color: #a49fad;
  font-size: 0.7rem;
}

.player .player-info-details a {
  color: #a49fad;
}

.player .player-controls {
  color: #fff;
  font-size: 1.2rem;
  margin: 5px 10px;
  text-align: center;
}

.player .player-info {
  display: none;
  vertical-align: top;
  position: relative;
  flex-grow: 1;
  /*max-width: 303px;*/
}

.player .player-info .player-info-bottom {
  position: absolute;
  margin-top: 10px;
  bottom: 0;
  width: 100%;
}

.player .player-info-main {
  margin: 10px;
}


.player .player-info .player-info-bottom .stream-updated {
  text-align: center;
  margin-bottom: 10px;
  color: #a49fad;
  font-size: 0.8rem;
}

.player .color-bar {
}

.player .color-bar-item {
  line-height: 5px;
  font-size: 5px;
  border-right: 1px solid #1A1A1A;
}

.player .rank-icon {
  border: 0;
  width: 44px;
  height: 44px;
  display: table-cell;
  float: none;
}

.player .color-bar-holder {
  background-color: #444;
  flex-grow: 1;
  font-size: 0.9rem;
  border-radius: 5px;
}


.player .color-bar-holder .color-bar-percent {
  float: right;
  color: #000;
  font-weight: normal;
  padding-right: 5px;
  /*mix-blend-mode: difference;*/
}


.player .color-bar-holder .color-bar-fill {
  margin-bottom: 5px;
  border-radius: 5px;
  padding-left: 5px;
  color: #000;
  white-space: nowrap;
}

#twitch-player {
  /* display: inline-block; */
  width: 100%;
  height: 100%;
  /* margin: 0 auto; */
}

.box {
    margin: 0px 10px 20px 10px;
    padding: 20px;
    background-color: white;
    /*-webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);*/
    border: 1px solid #ddd;
    border-top: 1px solid #eee;
    /*border-bottom: 2px solid #ddd;*/
    box-shadow: 0 2px 2px -2px #999;
}

.box-col {
  padding: 0;
}

.box-side {
  margin: 0px 0px 20px 10px;
  width: 274px;
}

.box-main {
  margin: 0px 10px 20px 10px;
  padding: 10px;
}

.box-main .streams {
  padding: 0px;
}

.sort-filter {
  margin-right: 10px;
}

.box hr {
    margin: 20px -20px;
    border-top: 1px solid #ebebeb;
}

.overwatch-character-box {
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  border: 1px solid #CFCFCF;
  border-radius: 2px;
}

.filter {
  margin-bottom: 20px;
}

.filter.last {
  margin-bottom: 0;
}

.filter h3 {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  color: #727272;
  letter-spacing: 1px;
}

.language-badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  background-color: #636c72;
  margin-left: 4px;
}

.overwatch-hero.unavailable, .overwatch-rank.unavailable,
.hearthstone-rank.unavailable, .hearthstone-class.unavailable,
.hearthstone-arena-score.unavailable, .language-text.unavailable {
  opacity: 0.2;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.overwatch-hero {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  background-size: cover;
  width: 54px;
  height: 50px;
  text-align: center;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  background-color: #F5F5F5;
  opacity: 1.0;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.hearthstone-rank {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  background-size: cover;
  width: 54px;
  height: 55px;
  /*padding: 16px;*/
  display: inline-block;
  text-align: center;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  background-color: #F5F5F5;
  opacity: 1.0;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.hearthstone-class {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  background-size: cover;
  width: 54px;
  height: 54px;
  display: inline-block;
  text-align: center;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  background-color: #F5F5F5;
  opacity: 1.0;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.hearthstone-arena-score {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  background-size: cover;
  width: 54px;
  height: 54px;
  display: inline-block;
  text-align: center;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  background-color: #F5F5F5;
  opacity: 1.0;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.overwatch-rank {
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  background-size: cover;
  width: 54px;
  height: 54px;
  display: inline-block;
  text-align: center;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 4px;
  border: 1px solid #CFCFCF;
  background-color: #F5F5F5;
  opacity: 1.0;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

.filter-header h1 {
  font-weight: normal;
  margin-bottom: 0;
}

.filter-header {
  margin: 0 10px 16px 10px;
}

.filter-side {
  display: none;
}

.filter-main {
  /*padding-right: 10px;*/
}

.overwatch-hero-container {
  display: inline-block;
}

.overwatch-hero-bar {
  margin-top: 2px;
  height: 3px;
  width: 100%;
}

.stream-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  margin-bottom: 3px;
  color: #4CBAD4;
  font-weight: bold;
  line-height: 22px;
}

.stream-title a, .stream-title span {
  color: #4CBAD4;
}

.stream-title .link {
  display: none;
}

.stream-viewers {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  margin-bottom: 0;
  color: #727272;
}

.stream .stream-updated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  margin-bottom: 0;
  float: right;
  margin-left: 5px;
  color: #727272;
}

.stream-updated .fa-sync {
  margin-right: 5px;
}

.streams {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
}

.vods {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
}

.vod {
  width: 240px;
  max-width: 100%;
  flex: 1 0 auto;
  /*max-width: 240px;*/
  /*flex-grow: 4;*/
  /*margin-right: 20px;*/
  /*flex: 1;*/
  padding: 10px;
  border: 1px solid #fff;
}

#content a.streamer-link {
  color: #727272;
  font-weight: bold;
}

.stream {
  width: 240px;
  max-width: 100%;
  flex: 1 0 auto;
  /*max-width: 240px;*/
  /*flex-grow: 4;*/
  /*margin-right: 20px;*/
  /*flex: 1;*/
  padding: 10px;
  border: 1px solid #fff;
}

.stream-placeholder {
  padding: 0 10px;
  width: 240px;
  max-width: 100%;
  flex: 1 0 auto;
}

.thumbnail {
  width: 100%;
  opacity: 1.0;
}

.thumbnail:hover {
  opacity: 0.8;
  /*transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;*/
}

.selected-overwatch-character, .selected-overwatch-rank, .selected-hearthstone-rank {
    border: 1px solid #BA8D00 !important;
    background-color: #F8D733;
}

.hearthstone-tabs {
  margin: 0 10px 10px 10px;
}

.tabbed-box {
  border-top: 0;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #ddd #ddd #ddd;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: #ddd #ddd #f5f5f5;
  background-color: transparent;
}

.hearthstone-tabs .nav-item .active {
  /*-webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);*/
  /*-moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);*/
  /*box-shadow: 0px 1px 1px -4px rgba(0,0,0,0.25);*/
}

.hearthstone-tabs .nav-item a {
  /*border: 0;*/
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

.color-bar {
  display: table;
  width: 100%;
  margin-top: -1px;
  margin-bottom: 2px;
}

.color-bar-item {
  line-height: 5px;
  font-size: 5px;
  display: table-cell;
  border-right: 1px solid white;
}

.stream-image {
  position: relative;
  background-color: black;
  margin-bottom: 2px;
  cursor: pointer;
  display: none;
}

.stream-image-link .stream-image {
  display: block;
}

.thumbnail-arena {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: right;
  background: linear-gradient(to bottom, rgba(40, 40, 40, 0), rgba(40, 40, 40, 1));
}

.thumbnail-arena-wins-container {
  margin-bottom: -6px;
  margin-right: 2px;
}

.thumbnail-arena-empty-container {
  line-height: 10px;
  margin-bottom: 6px;
  margin-right: 6px;
}

.thumbnail-arena-empty {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #251C0D;
  border: 1px solid #8E6F41;
  line-height: 12px;
}

.thumbnail-arena-wins {
  width: 44px;
  height: 44px;
}

.thumbnail-arena-loss {
  background-image: url(/images/hearthstone-arena-wins/loss.png);
  background-size: cover;
}

.overwatch-bar-reinhardt {
  background-color: #919C9F;
}
.overwatch-bar-mei {
  background-color: #69A8EB;
}
.overwatch-bar-soldier76 {
  background-color: #66708F;
}
.overwatch-bar-lucio {
  background-color: #80C945;
}
.overwatch-bar-tracer {
  background-color: #DC9137;
}
.overwatch-bar-widowmaker {
  background-color: #9B61A1;
}
.overwatch-bar-zarya {
  background-color: #EC79B0;
}
.overwatch-bar-genji {
  background-color: #90EC31;
}
.overwatch-bar-reaper {
  background-color: #742F43;
}
.overwatch-bar-bastion {
  background-color: #6D816D;
}
.overwatch-bar-mccree {
  background-color: #A5464C;
}
.overwatch-bar-mercy {
  background-color: #EEE9B7;
}
.overwatch-bar-winston {
  background-color: #A0A2B8;
}
.overwatch-bar-wreckingball {
  background-color: #D29854;
}
.overwatch-bar-junkrat {
  background-color: #EDBA44;
}
.overwatch-bar-torbjorn {
  background-color: #BD6D62;
}
.overwatch-bar-orisa {
  background-color: #477939;
}
.overwatch-bar-zenyatta {
  background-color: #EEE577;
}
.overwatch-bar-roadhog {
  background-color: #B88746;
}
.overwatch-bar-pharah {
  background-color: #3574C3;
}
.overwatch-bar-dva {
  background-color: #F08CC2;
}
.overwatch-bar-hanzo {
  background-color: #B2AB7E;
}
.overwatch-bar-symmetra {
  background-color: #82B5C8;
}
.overwatch-bar-moira {
  background-color: #64303A;
}
.overwatch-bar-brigitte {
  background-color: #A3655E;
}
.overwatch-bar-ana {
  background-color: #6986AC;
}
.overwatch-bar-sombra {
  background-color: #6F51B3;
}
.overwatch-bar-doomfist {
  background-color: #6A403B;
}
.overwatch-bar-ashe {
  background-color: #595D5E;
}
.overwatch-bar-baptiste {
  background-color: #5FA2C1;
}
.overwatch-bar-sigma {
  background-color: #8C93A5;
}
.overwatch-bar-echo {
  background-color: #773950;
}

.hearthstone-bar-druid {
  background-color: #ff7d0a;
}
.hearthstone-bar-hunter {
  background-color: #abd473;
}
.hearthstone-bar-mage {
  background-color: #69ccf0;
}
.hearthstone-bar-paladin {
  background-color: #f58cba;
}
.hearthstone-bar-priest {
  background-color: #E5EBF0;
}
.hearthstone-bar-rogue {
  background-color: #FFF569;
}
.hearthstone-bar-shaman {
  background-color: #0061f3;
}
.hearthstone-bar-warlock {
  background-color: #9482c9;
}
.hearthstone-bar-warrior {
  background-color: #c79c68;
}
.hearthstone-bar-demonhunter {
  background-color: #617934;
}

/* SIDEBAR CODE */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

.logo {
  margin-left: 20px;
  /*width: 117px;*/
  height: 35px;
  /*margin-bottom: 10px;*/
}

.hero-icon, .rank-icon, .hearthstone-rank-icon, .hearthstone-arena-icon, .hearthstone-class-icon {
  float: right;
  width: 22px;
  height: 22px;

  background-size: cover;
  border-radius: 2px;
  border: 1px solid #E1E1E1;
  margin-left: 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.no-results, .loading-results {
  text-align: center;
  width: 100%;
  margin: 0px 0;
}

.no-results-text {
  margin-bottom: 0;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

.button {
  display: inline-block;
  background-color: #4CBAD4;
  padding: 6px 40px;
  box-shadow: 0 2px 0 0 #288FA8;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 1rem;
  border: 0;
}

.button[type=submit] {
  font-weight: normal;
}

.button-disabled {
  /*opacity: 0.5;*/
  /*cursor: normal;*/
}

.load-more {
  text-align: center;
  margin: 40px 0;
}

.load-more .button {
  padding: 6px 80px;
}

.save-filter-sidebar {
  /*padding-top: 30px;*/
  /*border-top: 1px solid #E0E0E0;*/
  /*margin-top: 30px;*/
}

.save-filter-sidebar .button {
  text-align: center;
  width: 100%;
}

.save-filter-sidebar input[type=text] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  font-size: 0.8rem;
  padding: 6px;
}

.save-filter-sidebar input::placeholder {
  color: #aaa;
}

.filter-more {
  margin-left: auto;
}

.filter-title .filter-header {
  display: none;
}

#top {
  line-height: 55px;
  background-color: #FFEF6B;
  border-bottom: 1px solid #F8D733;
  margin-bottom: 20px;
}

#top.home {
  border: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffef6b+0,ffe356+100 */
  background: #ffef6b; /* Old browsers */
  background-image: linear-gradient(-189deg, #FFEF6B 0%, #FFE93C 100%);
  box-shadow: 0 1px 0 0 #F8D733;
}

#home-banner {
  display: none;
  margin-bottom: 30px;
}

.home #home-banner {
  display: block;
}

.home-box h1 {
  color: #727272;
}

.home-box .view-all {
  float: right;
  font-size: 0.9rem;
  vertical-align: bottom;
  line-height: 1.8rem;
}

.home-box .view-all a {
  color: #727272;
}

.home-box h1 a {
  color: #727272;
}

#contact {
  margin-right: 40px;
  text-align: right;
  font-size: 0.9rem;
  /*width: 400px;*/
}

#footer {
  margin-bottom: 20px;
  text-align: left;
  margin-left: 25px;
}

#footer p {
  font-size: 0.8rem;
  line-height: 1rem;
}

#footer a {
  margin-right: 20px;
}

#footer-info {
  text-align: center;
}

#footer-info a {
  margin: 0;
}

#top-links .link {
  display: none;
}

#home-container-image {
  display: none;
}

.dropdown-menu {
  display: block;
  max-height: 200px;
  overflow-y: scroll;
  min-width: 150px;
}

.dropdown-menu button:focus {
  outline: none;
}

.dropdown-filter {
  display: inline-block;
}

.dropdown {

}

.dropdown-toggle {
  background-color: #F5F5F5;
  min-width: 150px;
  width: 100%;
  text-align: left;
}

.dropdown-toggle::after {
  position: absolute;
  left: 90%;
  top: 45%;
}

.dropdown-menu {
  background-color: #F5F5F5;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.dropdown-item {
  padding: 0.5rem 0.4rem;
  border-bottom: 1px solid #E1E1E1;
}

.btn, .dropdown-item {
  font-size: 0.8rem;
}

.btn {
  padding: 0.5rem 0.5rem;
}

.dropdown-item:hover {
  background-color: #288FA8;
  color: #FFFFFF;
}

.language-text {
  margin-left: 4px;
}

.player-info {
  display: none;
}

.filter-main .filter-header {
  display: none;
}

#about ol {
  list-style-position: inside;
  padding-left: 0;
}

#about h2 {
  font-weight: bold;
}

.about-images {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
}

.about-image {
  flex: 1 0 auto;
  padding: 10px;
  width: 400px;
  max-width: 100%;
}

.about-image img {
  border: 1px solid black;
  width: 100%;
}

.no-results-image {
  width: 77px;
  height: 75px;
}

.clear {
  clear: both;
}

.new-player {
  width:  100%;
  height: 100%;
}

.vod-main {
  /* width: 100%; */
  /* max-height: 788px; */
}

.vod-side {
  /* max-height: 788px; */
}

.matches-grid {
  --matches-grid-filter-height: 140px;
  display: grid;
  grid-template-columns: 274px 1fr;
  grid-gap: 10px;
  grid-auto-rows: var(--matches-grid-filter-height) minmax(0, 1fr);
  min-height: 0;
  min-width: 0;
  margin-bottom: 20px;
}

.matches-list {
  grid-row: 2;
  padding: 0;
  display: flex;
  flex-direction: column;;

  height: calc(56.25vw - ((274px + 40px) * (9/16)) - var(--matches-grid-filter-height) - 10px);
  max-height: min(calc(100vh - 160px - var(--matches-grid-filter-height) - 10px), calc(720px - var(--matches-grid-filter-height) - 10px));
}

.matches-main {
  grid-column: 2;
  grid-row: 1 / span 2;
  max-height: min(calc(100vh - 160px), 720px);
  height: calc((56.25vw) - ((274px + 40px) * (9/16)));
  width: 100%;
  background-color: #eee;
}

.box-side-vod {
  margin: 0;
}

.matches-filter {
  grid-row: 1;
  padding: 20px;
}

.match-link {
  padding: 6px 12px 6px 20px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  clear: both;
  font-size: 0.9rem;
}

.match-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.matches-list-items {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.matches-list-title {
  padding: 6px;
  font-size: 0.8rem;
  text-align: right;
}

.match-link.selected {
  background-color: #FFEF6B;
  border: 1px solid #F8D733;
}

.match-vs {
  /* float: right; */
  display: grid;
  grid-gap: 0.25rem;
  align-items: center;
  grid-template-columns: 24px 70px auto 70px 24px;
  white-space: nowrap;
  /* font-size: 0.8rem; */
}

.match-vs-1 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.match-vs-vs {
  text-align: center;
}

.match-vs-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

.matches-container {
  max-width: calc(100vh * (16/9));
  margin: 0 auto;
}

#new-twitch-player {
  width:  100%;
  height: 100%;
}

.match-list-thumb {
  /* width: 100px; */
  height: 60px;
  float: left;
}

.match-title {
  padding: 6px 20px;
  font-size: 0.8rem;
  text-align: center;
}

.matches-list hr {
  margin: 6px -6px;
}

/* Stolen from youtube lol */
.matches-list-items::-webkit-scrollbar {
  width: 8px;
}

.matches-list-items::-webkit-scrollbar-thumb {
  height: 56px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.matches-filter .dropdown-filter {
  display: block;
}

.matches-filter .dropdown-menu {
  width: 232px;
}

.matches-filter .dropdown, .dropdown-toggle {
  height: 42px;
}

.character-text {
  margin-left: 10px;
}

.character-text.unavailable {
  opacity: 0.2;
  transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
}

@media(max-width:840px) {
  .matches-grid {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    grid-auto-rows: var(--matches-grid-filter-height) 200px calc((56.25vw) - (30px * (9/16)));;
  }

  .matches-list {
    grid-row: 2;
    min-height: 200px;
    height: 200px;
    max-height: 200px;
  }

  .matches-main {
    grid-column: 1;
    grid-row: 3;
    /* max-height: min(calc(100vh - 160px), 720px); */
    height: 100%;

  }
}


@media(min-width:840px) {
  .player {
    display: grid;

    /* max-height: min(calc(100vh - 160px), 720px); */
    /* height: calc((56.25vw) - ((274px + 40px) * (9/16))); */
    /* goal height: 243.5625 */

    grid-template-columns: 1fr;
    /* 284px + 240px + 30px (outside padding) + 10px (indent) */
    grid-auto-rows: min(calc((56.25vw) - ((284px + 240px + 40px + 28px) * (9/16))), 400px);
  }

  .filter-side {
    display: block;
  }

  .filter-top {
    display: none;
  }

  .filter-title .filter-header, .filter-main .filter-header {
    display: block;
  }

  #auto-update-container {
    margin-bottom: 0;
  }

  .stream-title .link {
    display: inline;
  }

  .stream-title .stream-link {
    display: none;
  }

  .stream-image-link {
    display: none;
  }

  .stream-image {
    display: block;
  }
  .stream.selected {
    /*background-color: #f5f5f5;
    border: 1px solid #CFCFCF;*/

    background-color: #FFEF6B;
    border: 1px solid #F8D733;
  }

  .stream.selected .stream-title {
    color: #0275d8;
  }

  .stream.selected .hero-icon, .stream.selected .rank-icon,
  .stream.selected .hearthstone-rank-icon, .stream.selected .hearthstone-arena-icon,
  .stream.selected .hearthstone-class-icon {
    border: 1px solid #FFEF6B;
  }

  .overwatch-hero {
    width: 36px;
    height: 33px;
  }

  .hearthstone-rank {
    width: 36px;
    height: 37px;
  }

  .hearthstone-class {
    width: 36px;
    height: 36px;
  }

  .hearthstone-arena-score {
    width: 36px;
    height: 36px;
  }

  .overwatch-rank {
    width: 36px;
    height: 36px;
  }
}

@media(min-width:960px) {
    #home-container-text {
      position: relative;
      right: -40px;
      height: 340px;
      padding: 0;
    }

    #home-container-content {
      margin-left: 60px;
    }

    #home-container-image {
      display: inline;
    }
}

@media(min-width:1024px) {
  .player {
    grid-template-columns: 1fr 240px;
  }

  .player .player-info {
    display: block;
  }

  #twitch-player {
    margin: 0;
  }
}

@media(min-width:768px) {
    .stream {
      max-width: 50%;
    }

    #top-links .link {
      display: inline;
    }

    #home-container-text h1 {
      font-size: 2rem;
      line-height: 3rem;
    }

    #home-container-content {
      margin-right: 100px;
      margin-left: 60px;
    }

    #contact {
      float: right;
    }

    #top-container a {
      margin-right: 30px;
    }

    .logo {
      margin-left: 40px;
    }
}

.shadow-popup {
  background-color: white;
}

.shadow-popup ul {
  list-style-type: none;
}

.smash-streamer-input {
  width: 100%;
  padding: 6px;
  border: 1px solid #aaa;
  border-radius: 2px;
}

.matches-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image, .error-image {
  width: 77px;
  height: 75px;
}

.infinite-scroll-loading {
  text-align: center;
}

.smash-match-intro {
  height: 100%;
  padding: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30pt;
  line-height: 30pt;
  /* color: #333; */
  color: #bbb;
}

.smash-match-intro-video-icon {
  font-size: 55pt;
}
